@keyframes boat-sway {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.logo {
  animation: boat-sway 3s infinite;
}


.buton-submit{
  font-size: 1.15rem;
  font-weight: 600;
}