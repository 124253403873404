body {
  background-color: #f8f9fa;
}

.form-control:focus {
  border-color: #1982B2;
  box-shadow: none;
}

.btn-primary {
  background-color: #1982B2;
  border-color: #1982B2;
}

.btn-primary:hover {
  background-color: #136387;
  border-color: #136387;
}
