/* src/components/styles/Home.css */
.product-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
}

.search-bar {
  margin: 20px auto;
  width: 80%;
}

.pagination-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
