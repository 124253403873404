/* src/components/styles/Cart.css */
.carrito-title-container {
  display: flex !important;
}

.carrito-title {
  margin-bottom: 40px;
}

.carrito-icon-title {
  margin-right: 10px;
  width: 35px;
  height: 35px;
}

.cart-item {
  display: flex !important;
  align-items: center !important;
  width: 80% !important;
  margin: 5px auto !important;
  height: 150px !important;
  flex-direction: row !important;
  padding: 10px !important;
  margin-bottom: 20px !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
}

.cart-item-image-container {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-item-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.cart-item-body {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10px;
}

.cart-item-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cart-item-title, .cart-item-description {
  margin: 0 !important;
}

.cart-item-actions {
  display: flex !important;
  justify-content: right !important;
  align-items: right !important;
}

.cart-item-quantity {
  width: 100px !important;
  margin-right: 5px !important;
}

.cart-item-remove-button {
  padding: 10px !important;
  width: 60px !important;
}

.cart-actions {
  margin-top: 20px !important;
  display: flex !important;
  justify-content: flex-end !important;
}

.confirm-purchase-button {
  display: flex;
  align-items: center;
  background-color: #28a745;
  border-color: #28a745;
  color: white;
}

.confirm-purchase-button:hover {
  background-color: #218838;
  border-color: #1e7e34;
}

.confirm-purchase-icon {
  margin-right: 10px;
}
