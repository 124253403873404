/* src/components/styles/ProductItem.css */
.product-item {
  display: flex;
  align-items: center;
  width: 80% !important;
  margin: 5px auto !important;
  height: 150px !important;
  flex-direction: row !important;
  padding: 10px !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important
}

.product-image-container {
  width: 40% !important;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.product-image {
  max-width: 100% !important;
  max-height: 120px !important;
  object-fit: contain !important;
}

.product-body {
  width: 60% !important;
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  padding-left: 10px !important;
}

.product-info {
  flex: 1 !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}

.product-title, .product-description {
  margin: 0 !important;
}

.product-actions {
  display: flex !important;
  justify-content: right !important;
  align-items: right !important;
}

.product-quantity {
  width: 100px !important;
  margin-right: 5px !important;
}

.product-add-button {
  padding: 10px !important;
  width: 60px !important;
}
