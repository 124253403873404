.notfound-container {
  text-align: center;
  padding: 50px;
  color: #343a40;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.notfound-logo {
  width: 150px;
  margin-bottom: 20px;
  animation: scale 2s linear infinite, color-change 4s linear infinite;
}

@keyframes scale {
  0% {
    transform:scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes color-text-change {
  0%, 100% {
    color: #343a40;
  }
  50% {
    color: red;
  }
}

.notfound-container h1 {
  font-size: 5em;
  margin-bottom: 20px;
  animation: color-text-change 3s
}

.notfound-container h2 {
  font-size: 2em;
  margin-bottom: 10px;
}

.notfound-container p {
  font-size: 1.2em;
  margin-bottom: 5px;
}
