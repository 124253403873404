.user-name {
  font-size: 1.15em; 
  margin-right: 10px;
  font-weight: 600;
  color: #9B9D9E;
  text-decoration: none !important;
}

.user-avatar {
  width: 40px;
  height: 40px;
  margin-right: 20px;
  border-radius: 50%;
}

.user-profile-text {
  font-weight: 600;
  color: #9B9D9E;
  text-decoration: none !important;
  display: none;
}

.profile-link:hover,
.profile-link:focus,
.profile-link:active {
  text-decoration: none !important;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  background-color: #212529 !important;
  text-align: center !important;
  border: none !important;
}

.dropdown-item {
  color: #9B9D9E !important;
  text-align: center !important;
  font-weight: 600 !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #495057 !important;
  color: #fff !important;
}

.saigro-text {
  font-size: 1.5em;
  font-weight: 600;
  color: #1982B2;
  margin-left: 10px;
  transition: color 0.3s ease;
  margin-right: 40px;
}

.saigro-text:hover {
  color: #1a6a8e;
}

.saigro-text:focus,
.saigro-text:active {
  color: #2ba9e1;
}

.nav-link{
  margin-right: 15px;
}

.nav-link-icon {
  margin-right: 5px;
}

@media (max-width: 767px) {
  .profile-link .user-name,
  .profile-link .user-avatar {
    display: none;
  }

  .profile-link .user-profile-text {
    display: block;
    text-align: center;
    font-weight: 700;
    color: #9B9D9E;
    font-size: 1.15em;
  }

  .navbar-collapse {
    text-align: center;
  }

  .navbar-nav .nav-item{
    width: 100%;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .profile-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .saigro-text .nav-link{
    margin-right: 0px;
  }
  
  .dropdown-item {
    font-weight: 650 !important;
  }

}
